<mat-button-toggle-group (click)="toggleListView()" class="button-toggle-fill" [hideSingleSelectionIndicator]="true">
  <mat-button-toggle id="gridViewSelector"
    [ngClass]="{
      'btn-toggle-blue-off': !isListView,
      'btn-toggle-blue-on': isListView
    }"
    title="List view"
    aria-label="Toggle list view">
    <img alt="List view icon" width="16px" height="16px" src="/images/icon-list{{!isListView ? '' : '-inv'}}.svg" />
  </mat-button-toggle>
  <mat-button-toggle
    id="listViewSelector"
    [ngClass]="{
      'btn-toggle-blue-off': isListView,
      'btn-toggle-blue-on': !isListView
    }"
    title="Grid view"
    aria-label="Toggle grid view">
    <img alt="Grid view icon" width="16px" height="16px" src="/images/icon-module{{isListView ? '' : '-inv'}}.svg" />
  </mat-button-toggle>
</mat-button-toggle-group>