<div class="attribute-editor-component stock-library-container">
  <div
    class="attribute-editor-row flex-col"
    rvSpinner="stock-library-loader"
    [rvShowSpinner]="loading"
  >
    <basic-uploader uploaderId="image-list-uploader"
      [uploadManager]="uploadManager"
      [validType]="fileType"
      [fileList]="fileUploadList"
      [shown]="isUploading">
    </basic-uploader>
    <div [shown]="!isUploading">
      <div class="flex-row gap-3">
        <search-filter
          class="flex-grow"
          [filterConfig]="filterConfig"
          [search]="search"
          [doSearch]="boundSearch"
          iconSet="madero"
        ></search-filter>
        @if (fileType === 'image') {
          <mat-button-toggle-group
            class="button-toggle-fill button-toggle-orientation"
            name="orientation"
            [(ngModel)]="orientation"
            (change)="doSearch()"
            aria-label="Orientation"
            [hideSingleSelectionIndicator]="true"
          >
            <mat-button-toggle value="horizontal">
              <streamline-icon ariaLabel="Toggle orientation to horizontal" name="orientationLandscape" height="16" width="16"></streamline-icon>
            </mat-button-toggle>
            <mat-button-toggle value="vertical">
              <streamline-icon ariaLabel="Toggle orientation to vertical" name="orientationPortrait" height="16" width="16"></streamline-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        }
      </div>
      <div class="flex-row gap-3">
        <hr class="flex-grow">
        <div>Content by <strong><a class="madero-link" href="https://pixabay.com" target="_blank">Pixabay</a></strong></div>
      </div>
      <div
        #resultsGrid
        common-header-height
        class="results-grid flex-grow"
        [ngClass]="{ 'disabled': !termsAccepted }"
        scrollingList
        (scrollEndEvent)="loadResults()"
      >
        @if (!termsAccepted) {
          <div class="terms-conditions">
            <p class="px-2">
              By using this service, you agree to use images and videos from Pixabay
              according to their terms of service. You can find a summary of Pixabay's
              terms <a class="madero-link" target="_blank" href="https://pixabay.com/service/license-summary/">here</a> and
              and the full version <a class="madero-link" target="_blank" href="https://pixabay.com/service/terms/">here</a>.
            </p>
            <button type="button" class="btn btn-primary" (click)="acceptTerms()">Accept</button>
          </div>
        }
        @for (asset of results; track asset.id) {
          @if (fileType === 'image' && asset.webformatURL || fileType === 'video' && asset.videos?.tiny?.thumbnail) {
            <div tabindex="0" class="stock-image u_clickable" (click)="selectAsset(asset.id)" (keyup.enter)="selectAsset(asset.id)" [ngClass]="{ 'selected': selectedIds.includes(asset.id) }">
              <img loading="lazy" [src]="fileType === 'image' ? asset.webformatURL : asset.videos.tiny.thumbnail">
            </div>
          }
        }
      </div>
      <div class="mt-3">
        <button tabindex="0" type="button" class="btn btn-primary btn-block" (click)="done()">I'm Done</button>
      </div>
    </div>
  </div>
</div>
