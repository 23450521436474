export const environment = {
  production: true,
  BLUEPRINT_URL: 'https://widgets.risevision.com/stable/templates/PRODUCT_CODE/blueprint.json',
  canvaApiKey: '_Ow6FgJpQ3S_k0Mgyo1UX2nM',
  // AngularJS config
  CORE_URL: 'https://api.risevision.com/_ah/api',
  STORE_SERVER_URL: 'https://api.risevision.com/store/',
  VIEWER_URL: 'https://widgets.risevision.com/viewer',
  SHARED_SCHEDULE_URL: 'https://widgets.risevision.com/viewer/?type=sharedschedule&id=SCHEDULE_ID',
  ALERTS_WS_URL: 'https://api.risevision.com/alerts/cap',
  TAG_MANAGER_CONTAINER_ID: 'GTM-MMTK3JH',
  TAG_MANAGER_AUTH: null,
  TAG_MANAGER_ENV: null,
  SCREEN_SHARING_SERVICE_URL: 'wss://services2.risevision.com/screensharing/',
  MESSAGING_PRIMUS_URL: 'https://services.risevision.com/messaging/primus?displayId=apps&machineId=1',
  OAUTH_TOKEN_PROVIDER_URL: 'https://services.risevision.com/oauthtokenprovider/',
  TWITTER_SERVICE_URL: 'https://services.risevision.com/twitter/',
  GOOGLE_SERVICE_URL: 'https://services.risevision.com/google/',
  DATA_SERVICE_URL: 'https://services.risevision.com/data/',
  POWERBI_SERVICE_URL: 'https://services.risevision.com/data/ms-pbi/',
  MICROSOFT_OUTLOOK_SERVICE_URL: 'https://services.risevision.com/data/ms-graph/calendar/',
  MICROSOFT_ONEDRIVE_SERVICE_URL: 'https://services.risevision.com/data/ms-graph/onedrive/',
  HUBSPOT_ACCOUNT: '2700250',
  STRIPE_API_KEY: 'pk_live_31dWkTWQU125m2RcWpK4HQBR',
  HTML_TEMPLATE_URL: 'https://widgets.risevision.com/stable/templates/PRODUCT_CODE/src/template.html',
  SSO_LOGIN_URL: 'https://rvaserver2.appspot.com/sso/login',
  SPRING_ENDPOINT_URL: 'https://api.risevision.com/api/core',
  SPRING_STORAGE_ENDPOINT_URL: 'https://api.risevision.com/storage/api/storage',
  SPRING_STORE_ENDPOINT_URL: 'https://api.risevision.com/store/api/store',
  PIXABAY_API_KEY: '42941787-c47b7eb6f834a8cdbc497637d'
};
