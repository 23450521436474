// AngularJS

// external
export abstract class LazyLoadService {
  [key: string]: any;
}
export const $lazyLoadProvider = {
  provide: LazyLoadService,
  useFactory: function ($injector: any) {
    return $injector.get('$ocLazyLoad');
  },
  deps: ['$injector']
};

// common-header
export abstract class HelpWidgetFactory {
  [key: string]: any;
}
export const helpWidgetFactoryProvider = {
  provide: HelpWidgetFactory,
  useFactory: function ($injector: any) {
    return $injector.get('helpWidgetFactory');
  },
  deps: ['$injector']
};

export abstract class COUNTRIES {
  [key: string]: any;
  [Symbol.iterator]: () => Iterator<{ code: string, name: string }>;
}
export const countriesProvider = {
  provide: COUNTRIES,
  useFactory: function ($injector: any) {
    return $injector.get('COUNTRIES');
  },
  deps: ['$injector']
};

// common/services
export abstract class FileDownloader extends Function {
  [key: string]: any;
}
export const fileDownloaderProvider = {
  provide: FileDownloader,
  useFactory: function ($injector: any) {
    return $injector.get('fileDownloader');
  },
  deps: ['$injector']
};

export abstract class ProxyFileDownloader extends Function {
  [key: string]: any;
}
export const proxyFileDownloaderProvider = {
  provide: ProxyFileDownloader,
  useFactory: function ($injector: any) {
    return $injector.get('proxyFileDownloader');
  },
  deps: ['$injector']
};

export abstract class OutsideClickHandler extends Function {
  [key: string]: any;
}
export const outsideClickHandlerProvider = {
  provide: OutsideClickHandler,
  useFactory: function ($injector: any) {
    return $injector.get('outsideClickHandler');
  },
  deps: ['$injector']
};

export abstract class CompanyAssetsService {
  [key: string]: any;
}
export const companyAssetsServiceProvider = {
  provide: CompanyAssetsService,
  useFactory: function ($injector: any) {
    return $injector.get('companyAssetsFactory');
  },
  deps: ['$injector']
};

// components/core-api-client
export abstract class StoreAuthorizationService {
  [key: string]: any;
}
export const storeAuthorizationProvider = {
  provide: StoreAuthorizationService,
  useFactory: function ($injector: any) {
    return $injector.get('storeAuthorization');
  },
  deps: ['$injector']
};

// components/gapi
export abstract class GapiLoader extends Function {
}
export const gapiLoaderProvider = {
  provide: GapiLoader,
  useFactory: function ($injector: any) {
    return $injector.get('gapiLoader');
  },
  deps: ['$injector']
};

export abstract class CoreAPILoader extends Function {
}
export const coreAPILoaderProvider = {
  provide: CoreAPILoader,
  useFactory: function ($injector: any) {
    return $injector.get('coreAPILoader');
  },
  deps: ['$injector']
};

export abstract class RiseAPILoader extends Function {
}
export const riseAPILoaderProvider = {
  provide: RiseAPILoader,
  useFactory: function ($injector: any) {
    return $injector.get('riseAPILoader');
  },
  deps: ['$injector']
};

// components/logging
export abstract class ExceptionHandler extends Function  {
  [key: string]: any;
}
export const exceptionHandlerProvider = {
  provide: ExceptionHandler,
  useFactory: function ($injector: any) {
    return $injector.get('$exceptionHandler');
  },
  deps: ['$injector']
};

export abstract class AnalyticsFactory {
  [key: string]: any;
}
export const analyticsFactoryProvider = {
  provide: AnalyticsFactory,
  useFactory: function ($injector: any) {
    return $injector.get('analyticsFactory');
  },
  deps: ['$injector']
};

export abstract class BigQueryLogging {
  [key: string]: any;
}
export const bigQueryLoggingProvider = {
  provide: BigQueryLogging,
  useFactory: function ($injector: any) {
    return $injector.get('bigQueryLogging');
  },
  deps: ['$injector']
};

// components/scrolling-list
export abstract class ProcessErrorCode extends Function {
}
export const processErrorCodeProvider = {
  provide: ProcessErrorCode,
  useFactory: function ($injector: any) {
    return $injector.get('processErrorCode');
  },
  deps: ['$injector']
};

export abstract class ScrollingListService  extends Function{
}
export const scrollingListServiceProvider = {
  provide: ScrollingListService,
  useFactory: function ($injector: any) {
    return $injector.get('ScrollingListService');
  },
  deps: ['$injector']
};

// components/url-field
export abstract class InsecureUrl  extends Function{
}
export const insecureUrlProvider = {
  provide: InsecureUrl,
  useFactory: function ($injector: any) {
    return $injector.get('insecureUrl');
  },
  deps: ['$injector']
};

// components/userstate
export abstract class UrlStateService {
  [key: string]: any;
}
export const urlStateServiceProvider = {
  provide: UrlStateService,
  useFactory: function ($injector: any) {
    return $injector.get('urlStateService');
  },
  deps: ['$injector']
};

export abstract class UserAuthFactory {
  [key: string]: any;
}
export const userAuthFactoryProvider = {
  provide: UserAuthFactory,
  useFactory: function ($injector: any) {
    return $injector.get('userAuthFactory');
  },
  deps: ['$injector']
};

export abstract class OpenidConnectLoader  extends Function{
}
export const openidConnectLoaderProvider = {
  provide: OpenidConnectLoader,
  useFactory: function ($injector: any) {
    return $injector.get('openidConnectLoader');
  },
  deps: ['$injector']
};

export abstract class CanAccessApps  extends Function{
}
export const canAccessAppsProvider = {
  provide: CanAccessApps,
  useFactory: function ($injector: any) {
    return $injector.get('canAccessApps');
  },
  deps: ['$injector']
};

// editor/services
export abstract class GadgetsApi {
  [key: string]: any;
}
export const gadgetsApiProvider = {
  provide: GadgetsApi,
  useFactory: function ($injector: any) {
    return $injector.get('gadgetsApi');
  },
  deps: ['$injector']
};

// storage/services
export abstract class BlobDownloaderFactory extends Function {
}
export const blobDownloaderFactoryProvider = {
  provide: BlobDownloaderFactory,
  useFactory: function ($injector: any) {
    return $injector.get('downloadBlob');
  },
  deps: ['$injector']
};

export abstract class DownloadService {
  [key: string]: any;
}
export const downloadServiceProvider = {
  provide: DownloadService,
  useFactory: function ($injector: any) {
    return $injector.get('downloadFactory');
  },
  deps: ['$injector']
};